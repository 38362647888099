import { Stack, useTheme } from '@mui/material'
import { PropsWithChildren } from 'react'

import { AppFooter, AppNavbar } from '@/common'
import { vh } from '@/theme/helpers'

const MainLayout = ({ children }: PropsWithChildren) => {
  const { breakpoints } = useTheme()

  return (
    <Stack
      direction='column'
      minHeight={vh(100)}
      width='100%'
      sx={{
        backgroundColor: theme => theme.palette.background.light,
        [breakpoints.down('md')]: {
          flexDirection: 'column',
          gap: 0,
        },
      }}
    >
      <AppNavbar />
      <Stack flex={1} sx={{ overflowY: 'hidden' }}>
        {children}
      </Stack>
      <AppFooter />
    </Stack>
  )
}

export default MainLayout
