import { Stack, StackProps, useMediaQuery, useTheme } from '@mui/material'
import { NavLink } from 'react-router-dom'

import { Icons, RoutePaths } from '@/enums'
import { UiIcon } from '@/ui'

export default function AppLogo({ ...rest }: StackProps) {
  const { palette, typography, breakpoints } = useTheme()
  const isSmUp = useMediaQuery(breakpoints.up('sm'))
  return (
    <Stack
      component={NavLink}
      to={RoutePaths.Root}
      alignItems='center'
      direction='row'
      spacing={3}
      sx={{
        color: palette.text.primary,
        ...typography.h5,
        ...rest.sx,
      }}
    >
      <UiIcon name={Icons.App} size={8} sx={{ color: palette.text.primary }} />
      {isSmUp && 'moxdeba.ge'}
    </Stack>
  )
}
