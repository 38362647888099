import { constants, utils } from 'ethers'
import { useCallback, useEffect, useState } from 'react'

import { encodeMessageProof } from '@/helpers'
import { useWeb3State } from '@/store'
import { useIdentityState } from '@/store/modules/identity'
import { OrderSide } from '@/types'
import { OrderStruct } from '@/types/contracts/NegRiskCtfExchange'

const MAX_TIMESTAMP_VALUE = (Math.pow(2, 32) - 1).toString()

export const useOrder = (): {
  order: Partial<OrderStruct>
  createOrder: ({
    tokenId,
    makerAmount,
    takerAmount,
    side,
  }: {
    tokenId: string
    makerAmount: string
    takerAmount: string
    side: OrderSide
  }) => Partial<OrderStruct>
  encodeWithProof: (signedOrder: string) => string
} => {
  const [order, setOrder] = useState<Partial<OrderStruct>>({
    taker: constants.AddressZero,
    expiration: MAX_TIMESTAMP_VALUE,
    feeRateBps: '0',
    signatureType: 0,
  })
  const { isConnected, address: providerAddress, providerType } = useWeb3State()
  const { address: sessionAddress, zkProofJson } = useIdentityState()

  const createOrder = useCallback(
    ({
      tokenId,
      makerAmount,
      takerAmount,
      side,
    }: {
      tokenId: string
      makerAmount: string
      takerAmount: string
      side: OrderSide
    }) => {
      // TODO: validate fields
      return {
        ...order,
        salt: utils.hexlify(utils.randomBytes(32)),
        tokenId,
        makerAmount,
        takerAmount,
        side,
      }
    },
    [order],
  )

  const encodeWithProof = useCallback(
    (signedOrder: string) => {
      const parsedProof = JSON.parse(zkProofJson).proof
      const identityProof = {
        a: [parsedProof.pi_a[0], parsedProof.pi_a[1]],
        b: [
          [parsedProof.pi_b[0][1], parsedProof.pi_b[0][0]],
          [parsedProof.pi_b[1][1], parsedProof.pi_b[1][0]],
        ],
        c: [parsedProof.pi_c[0], parsedProof.pi_c[1]],
      }
      const orderProof = {
        identityProof: identityProof,
        signature: signedOrder,
      }
      return encodeMessageProof(orderProof)
    },
    [zkProofJson],
  )

  useEffect(() => {
    const makerAddress = providerAddress
    const signerAddress = providerType === 'united-space' ? sessionAddress : providerAddress

    const isSameAddress = order.maker === makerAddress && order.signer === signerAddress

    if (!isConnected || !(makerAddress && signerAddress) || isSameAddress) return
    setOrder({
      ...order,
      maker: makerAddress,
      signer: signerAddress,
    })
  }, [isConnected, providerAddress, sessionAddress, order, providerType])

  return { order, createOrder, encodeWithProof }
}
