import {
  Button,
  ListItemIcon,
  MenuItem,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { AppMenu } from '@/common'
import UnitedSpaceTopUp from '@/common/UnitedSpaceTopUp'
import { Icons, RoutePaths } from '@/enums'
import { formatNullifier } from '@/helpers'
import { useWeb3State, web3Store } from '@/store'
import { UiIcon } from '@/ui'

export default function ProfileMenu() {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const { palette, breakpoints } = useTheme()
  const { t } = useTranslation()
  const { address } = useWeb3State()

  const isMdUp = useMediaQuery(breakpoints.up('md'))

  const menuItemSx: SxProps = { py: 2.5, px: 4, width: '100%' }

  return (
    <>
      <Button
        variant='outlined'
        size='medium'
        endIcon={isMdUp && <UiIcon name={Icons.CarretDown} size={5} />}
        onClick={event => setAnchorEl(event.currentTarget)}
        sx={{
          '&': {
            [breakpoints.down('md')]: {
              p: 0,
              minWidth: 0,
              width: 40,
              height: 40,
            },
          },
        }}
      >
        {isMdUp ? (
          formatNullifier(address || '')
        ) : (
          <UiIcon name={Icons.User} size={5} minWidth={10} />
        )}
      </Button>
      <AppMenu anchorEl={anchorEl} id='profile-menu' onClose={() => setAnchorEl(null)}>
        <MenuItem sx={menuItemSx} component={NavLink} to={RoutePaths.Account}>
          <ListItemIcon>
            <UiIcon name={Icons.User} size={5} color={palette.text.primary} />
          </ListItemIcon>
          <Typography variant='buttonMedium'>{t('profile-menu.account-lbl')}</Typography>
        </MenuItem>

        <UnitedSpaceTopUp
          trigger={startCb => {
            return (
              <MenuItem
                sx={menuItemSx}
                component='button'
                onClick={async () => {
                  await startCb()
                }}
              >
                <ListItemIcon>
                  <UiIcon name={Icons.ArrowUp} size={5} color={palette.text.primary} />
                </ListItemIcon>
                <Typography variant='buttonMedium'>{t('profile-menu.top-up-lbl')}</Typography>
              </MenuItem>
            )
          }}
        />

        <MenuItem sx={menuItemSx} onClick={web3Store.disconnect}>
          <ListItemIcon>
            <UiIcon name={Icons.Logout} size={5} color={palette.error.main} />
          </ListItemIcon>
          <Typography variant='buttonMedium' color={palette.error.main}>
            {t('profile-menu.sign-out-lbl')}
          </Typography>
        </MenuItem>
      </AppMenu>
    </>
  )
}
