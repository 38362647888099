import { PROVIDERS } from '@distributedlab/w3p'
import { Button, MenuItem, Typography } from '@mui/material'
import { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useEffectOnce } from 'react-use'

import { AppMenu, UnitedSpaceLogin } from '@/common/index'
import { config } from '@/config'
import { Icons } from '@/enums'
import { bus } from '@/helpers'
import { web3Store } from '@/store'
import { UiIcon } from '@/ui'

type Props = {
  handleLoginSuccess?: () => void
}

export default function ConnectWallet({ handleLoginSuccess }: Props) {
  const menuAnchor = useRef(null)

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const { t } = useTranslation()

  const menuItemSxOverride = {
    p: 2,
    width: 264,
    justifyContent: 'flex-start',
    gap: 4.5,
  }

  const openMenu = useCallback(() => {
    setAnchorEl(menuAnchor.current)
  }, [])

  useEffectOnce(() => {
    bus.on('OpenConnectDropdown', openMenu)

    return () => {
      bus.off('OpenConnectDropdown', openMenu)
    }
  })

  return config.IS_TESTING ? (
    <>
      <Button ref={menuAnchor} size='medium' onClick={openMenu} variant='contained'>
        {t('app-navbar.connect-button-lbl')}
      </Button>
      <AppMenu
        anchorEl={anchorEl}
        id='connect-wallet-menu'
        onClose={() => setAnchorEl(null)}
        sx={{
          '& .MuiMenu-list': {
            width: 'auto',
          },
        }}
      >
        <UnitedSpaceLogin
          trigger={startCb => {
            return (
              <MenuItem
                sx={menuItemSxOverride}
                onClick={async () => {
                  await startCb()
                  setAnchorEl(null)
                  if (handleLoginSuccess) {
                    handleLoginSuccess()
                  }
                }}
              >
                <UiIcon name={Icons.UnitedSpaceApp} size={5} />
                <Typography variant='buttonMedium'>
                  {t('app-navbar.united-space-app-lbl')}
                </Typography>
                <UiIcon
                  name={Icons.ArrowRight}
                  size={5}
                  ml='auto'
                  color={({ palette }) => palette.text.secondary}
                />
              </MenuItem>
            )
          }}
        />
        <MenuItem sx={menuItemSxOverride} onClick={() => web3Store.init(PROVIDERS.Metamask)}>
          <UiIcon name={Icons.Metamask} size={5} />
          <Typography variant='buttonMedium'>{t('app-navbar.metamask-lbl')}</Typography>
          <UiIcon
            name={Icons.ArrowRight}
            size={5}
            ml='auto'
            color={({ palette }) => palette.text.secondary}
          />
        </MenuItem>
      </AppMenu>
    </>
  ) : (
    <UnitedSpaceLogin
      trigger={startCb => {
        return (
          <Button
            size='medium'
            onClick={async () => {
              await startCb()
              if (handleLoginSuccess) {
                handleLoginSuccess()
              }
            }}
            variant='contained'
            sx={{ width: '100%' }}
          >
            {t('app-navbar.connect-button-lbl')}
          </Button>
        )
      }}
    />
  )
}
