import { JsonApiError } from '@distributedlab/jac'
import log from 'loglevel'

import { BusEvents } from '@/enums'

import { bus } from './event-bus'

export class ErrorHandler {
  static isError(error: unknown): error is Error {
    return error instanceof Error || (error instanceof Object && 'message' in error)
  }

  static process(error: unknown, message = ''): void {
    if (!ErrorHandler.isError(error)) return
    let nestedErrorMessages = ''

    if (error instanceof JsonApiError) {
      const nestedErrors = error.nestedErrors ?? []

      nestedErrorMessages = nestedErrors
        .map(err => (err.meta as Record<string, unknown>)?.error || '')
        .join(', ')
    }

    bus.emit(BusEvents.error, { message: nestedErrorMessages || message || error.message })
    ErrorHandler.processWithoutFeedback(error)
  }

  static processWithoutFeedback(error: unknown): void {
    if (!ErrorHandler.isError(error)) return
    log.error(error)
  }
}
