import {
  Button,
  IconButton,
  MenuItem,
  Stack,
  Switch,
  SxProps,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AppMenu, ProfileMenu } from '@/common'
import AppLogo from '@/common/AppLogo'
import ConnectWallet from '@/common/ConnectWallet'
import LanguageSwitch from '@/common/LanguageSwitch'
import UnitedSpaceTopUp from '@/common/UnitedSpaceTopUp'
import Web3DetailsModal from '@/common/Web3DetailsModal'
import { config } from '@/config'
import { Icons } from '@/enums'
import { formatAmount } from '@/helpers'
import { ENGLISH_LOCALE, GEORGIAN_LOCALE, STORAGE_KEY } from '@/locales'
import { uiStore, useWeb3State } from '@/store'
import { UiContainer, UiIcon } from '@/ui'

const menuItemSx: SxProps = { p: 2, justifyContent: 'space-between' }

const AppNavbar = () => {
  const { palette, breakpoints } = useTheme()
  const { isConnected, balance } = useWeb3State()
  const [isWeb3DetailsOpen, setIsWeb3DetailsOpen] = useState(false)
  const [isTopUpOpen, setIsTopUpOpen] = useState(false)

  const openTopUpModal = useCallback(() => {
    setIsTopUpOpen(true)
  }, [])

  return (
    <Stack py={5} borderBottom={1} borderColor={palette.action.active}>
      <UiContainer
        direction='row'
        justifyContent='space-between'
        sx={{
          [breakpoints.down('sm')]: { py: 0 },
          [breakpoints.down('md')]: { px: 4 },
        }}
      >
        <AppLogo />
        <Stack spacing={4} direction='row'>
          {isConnected ? (
            <>
              <Tooltip title={formatAmount(balance)}>
                <Stack
                  direction='row'
                  spacing={2}
                  alignItems='center'
                  sx={theme => ({
                    color: theme.palette.primary.dark,
                    backgroundColor: theme.palette.primary.lighter,
                    borderRadius: 1000,
                    p: theme.spacing(2.5, 6),
                  })}
                >
                  <UiIcon name={Icons.Points} size={5} />
                  <Typography variant='buttonMedium'>{formatAmount(balance)}</Typography>
                </Stack>
              </Tooltip>
              <ProfileMenu />
            </>
          ) : (
            <ConnectWallet handleLoginSuccess={openTopUpModal} />
          )}
          <SettingsMenu />
          <UnitedSpaceTopUp isInitiallyOpened={isTopUpOpen} />
          {config.IS_TESTING && (
            <>
              {isConnected && (
                <Button size='medium' onClick={() => setIsWeb3DetailsOpen(true)}>
                  Open Approves Modal
                </Button>
              )}
              <Web3DetailsModal
                open={isWeb3DetailsOpen}
                onClose={() => setIsWeb3DetailsOpen(false)}
              />
            </>
          )}
        </Stack>
      </UiContainer>
    </Stack>
  )
}

const SettingsMenu = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const { t, i18n } = useTranslation()
  const { paletteMode, setPaletteMode } = uiStore

  const isDarkMode = paletteMode === 'dark'

  const togglePaletteMode = useCallback(() => {
    setPaletteMode(isDarkMode ? 'light' : 'dark')
  }, [setPaletteMode, isDarkMode])

  const toggleLanguages = useCallback(() => {
    const newLanguage = i18n.language === ENGLISH_LOCALE ? GEORGIAN_LOCALE : ENGLISH_LOCALE
    i18n.changeLanguage(newLanguage)
    localStorage.setItem(STORAGE_KEY, newLanguage)
    window.location.reload()
  }, [i18n])

  return (
    <>
      <IconButton onClick={event => setAnchorEl(event.currentTarget)}>
        <UiIcon name={Icons.List} size={5} />
      </IconButton>
      <AppMenu anchorEl={anchorEl} id='settings-menu' onClose={() => setAnchorEl(null)}>
        <MenuItem sx={menuItemSx} onClick={() => toggleLanguages()}>
          <Typography variant='buttonMedium'>{t('app-navbar.language-switch-lbl')}</Typography>
          <LanguageSwitch
            checked={i18n.language === GEORGIAN_LOCALE}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </MenuItem>

        <MenuItem sx={menuItemSx} onClick={togglePaletteMode}>
          <Typography variant='buttonMedium'>{t('app-navbar.theme-switch-dark-lbl')}</Typography>
          <Switch
            sx={theme => ({
              '& .MuiSwitch-thumb': {
                backgroundColor: theme.palette.common.white,
              },
            })}
            checked={isDarkMode}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </MenuItem>
      </AppMenu>
    </>
  )
}

export default AppNavbar
