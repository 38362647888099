import { Box, Stack, Typography, useTheme } from '@mui/material'
import { QRCode } from 'react-qrcode-logo'

type QRCodeDisplayProps = {
  deepLink: string
  caption: string
}

export function QRCodeDisplay({ deepLink, caption }: QRCodeDisplayProps) {
  const { spacing, palette } = useTheme()

  return (
    <Stack gap={6} alignItems='center' mb={6}>
      <Box
        sx={{
          position: 'relative',
          border: `1px solid ${palette.text.primary}`,
          borderRadius: spacing(3),
          p: 1,
        }}
      >
        <QRCode
          size={400}
          value={deepLink}
          logoImage='/branding/logo.svg'
          logoWidth={48}
          logoHeight={48}
          logoPadding={12}
        />
      </Box>
      <Typography variant='subtitle2' color={palette.text.primary} textAlign='center'>
        {caption}
      </Typography>
    </Stack>
  )
}
