import { format as formatDate, isDate, Locale } from 'date-fns'
import { enUS, ka } from 'date-fns/locale'
import { use } from 'i18next'
import { initReactI18next } from 'react-i18next'

import resources from './resources'

export const STORAGE_KEY = 'moxdeba-app-locale'

export const ENGLISH_LOCALE = 'en-US'
export const GEORGIAN_LOCALE = 'ka-GE'

const locale = localStorage?.getItem(STORAGE_KEY) ?? GEORGIAN_LOCALE

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false
  }
}

export const dateFnsLocaleMap: Record<string, Locale> = {
  [ENGLISH_LOCALE]: enUS,
  [GEORGIAN_LOCALE]: ka,
}

// for configuration options read: https://www.i18next.com/overview/configuration-options
const i18n = use(initReactI18next).init({
  fallbackLng: locale,
  returnNull: false,
  lng: locale,
  resources,
  interpolation: {
    escapeValue: false, // not needed for React as it escapes by default
    format: (value, format = 'P', lng = 'en-US') => {
      if (isDate(value)) {
        return formatDate(value, format, { locale: dateFnsLocaleMap[lng] })
      }
      return value
    },
  },
})

export default i18n
