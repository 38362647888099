import { Link, Stack, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

import AppLogo from '@/common/AppLogo'
import { Transitions } from '@/theme/constants'
import { UiContainer } from '@/ui'

export default function AppFooter() {
  const { palette } = useTheme()
  const { t } = useTranslation()

  return (
    <Stack
      sx={{
        width: '100%',
        mt: 'auto',
      }}
      borderTop={1}
      borderColor={palette.action.active}
    >
      <UiContainer
        sx={{
          py: 6,
          height: 110,
          justifyContent: 'center',
        }}
      >
        <Stack
          direction='row'
          spacing={4}
          justifyContent={{ sm: 'space-between' }}
          alignItems='center'
        >
          <AppLogo sx={{ width: 'fit-content' }} />
          <Link
            href='https://t.me/+LXPng11ztm44MDMy'
            variant='body3'
            target='_blank'
            rel='noopener noreferrer'
            color={palette.text.secondary}
            sx={{
              transition: Transitions.Default,
              '&, &:hover': { textDecoration: 'underline' },
              '&:hover': { color: palette.text.primary },
            }}
          >
            {t('footer.telegram-link')}
          </Link>
        </Stack>
      </UiContainer>
    </Stack>
  )
}
