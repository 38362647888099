import { BN } from '@distributedlab/tools'
import { constants } from 'ethers'
import { useCallback, useEffect, useMemo } from 'react'

import { config } from '@/config'
import { ErrorHandler } from '@/helpers'
import { useWeb3State, web3Store } from '@/store'
import { CollateralToken__factory } from '@/types/contracts'

export const useCollateralToken = (address = config.COLLATERAL_TOKEN_CONTRACT) => {
  const { provider, address: userAddr, contractConnector } = useWeb3State()

  const contractInterface = useMemo(() => CollateralToken__factory.createInterface(), [])

  const contractInstance = useMemo(() => {
    if (!address || !contractConnector) return null

    return CollateralToken__factory.connect(address, contractConnector)
  }, [address, contractConnector])

  const loadBalance = useCallback(async () => {
    const address = provider.address
    if (!contractInstance || !address) return

    try {
      const balance = await contractInstance.balanceOf(address)
      web3Store.setBalance(balance.toString())
    } catch (e) {
      ErrorHandler.processWithoutFeedback(e)
    }
  }, [contractInstance, provider.address])

  useEffect(() => {
    const interval = setInterval(loadBalance, 5000)
    return () => clearInterval(interval)
  }, [loadBalance])

  useEffect(() => {
    loadBalance()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const approve = useCallback(
    (spender: string) => {
      if (!contractInstance || !address) throw new ReferenceError('Provider or contract not found')

      const data = contractInterface.encodeFunctionData('approve', [spender, constants.MaxUint256])

      const txBody = {
        to: address,
        data,
      }

      return provider.signAndSendTx?.(txBody)
    },
    [contractInstance, address, provider, contractInterface],
  )

  const getIsApproved = useCallback(
    async (spender: string) => {
      if (!contractInstance || !userAddr) throw new ReferenceError('Provider or contract not found')

      const allowance = await contractInstance.allowance(userAddr, spender)
      return allowance.eq(constants.MaxUint256)
    },
    [contractInstance, userAddr],
  )

  const mint = useCallback(
    async (amount: string) => {
      if (!contractInstance || !userAddr) throw new TypeError('Invalid address or provider')

      const data = contractInterface.encodeFunctionData('mint', [
        userAddr,
        BN.fromRaw(amount).value,
      ])

      const txBody = {
        to: address,
        data,
      }

      return provider?.signAndSendTx?.(txBody)
    },
    [contractInstance, address, provider, contractInterface, userAddr],
  )

  return {
    approve,
    getIsApproved,
    mint,
  }
}
