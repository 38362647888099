import {
  Button,
  CircularProgress,
  CssBaseline,
  Stack,
  ThemeProvider,
  Typography,
} from '@mui/material'
import { FC, HTMLAttributes, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'

import { ToastsManager } from '@/contexts'
import { useCollateralToken, useViewportSizes } from '@/hooks'
import { useLocalisedYupScheme } from '@/hooks/yup'
import { AppRoutes } from '@/routes'
import { authStore, useUiState, useWeb3State, web3Store } from '@/store'
import { createTheme } from '@/theme'

const App: FC<HTMLAttributes<HTMLDivElement>> = () => {
  const [isAppInitialized, setIsAppInitialized] = useState(false)

  const { signIn, signOut } = authStore
  const { address, providerType } = useWeb3State()
  const { paletteMode } = useUiState()
  useViewportSizes()
  useCollateralToken()
  useLocalisedYupScheme()

  const init = useCallback(async () => {
    try {
      providerType && (await web3Store.init())
    } catch (e) {
      // TODO: fix web3 store
    }
    setIsAppInitialized(true)
  }, [providerType])

  const theme = useMemo(() => createTheme(paletteMode), [paletteMode])

  useEffect(() => {
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // TODO: temp, remove after auth implementation
    if (address) {
      signIn(address)
      return
    }
    signOut()
  }, [address, signIn, signOut])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToastsManager>
        <div className='App' key='app_main'>
          {isAppInitialized ? (
            <ErrorBoundary
              FallbackComponent={FallbackComponent}
              onReset={() => window.location.reload()}
            >
              <AppRoutes />
            </ErrorBoundary>
          ) : (
            <Stack alignItems='center' justifyContent='center' flex={1}>
              <CircularProgress />
            </Stack>
          )}
        </div>
      </ToastsManager>
    </ThemeProvider>
  )
}

export const FallbackComponent = ({ resetErrorBoundary }: { resetErrorBoundary: () => void }) => {
  const { t } = useTranslation()
  return (
    <Stack alignItems='center' justifyContent='center' flex={1} spacing={4}>
      <Typography variant='h2'>{t('error-boundary.title')}</Typography>
      <Button onClick={resetErrorBoundary}>{t('error-boundary.button-lbl')}</Button>
    </Stack>
  )
}

export default memo(App)
