import { config } from '@config'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Dialog,
  Divider,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { ethers } from 'ethers'
import { PropsWithChildren, ReactElement, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { api } from '@/api/clients'
import AppBadges from '@/common/AppBadges'
import { DeepLinkConfirmation } from '@/common/DeepLinkConfirmation'
import { QRCodeDisplay } from '@/common/QRCodeDisplay'
import { ApiServicePaths, Icons } from '@/enums'
import { ErrorHandler, isMobile, sleep } from '@/helpers'
import { web3Store } from '@/store'
import { identityStore } from '@/store/modules/identity/identity.module'
import { UiIcon } from '@/ui'

type Props = {
  trigger: (startCb: () => Promise<void>) => ReactElement
} & PropsWithChildren

export default function UnitedSpaceLogin({ trigger }: Props) {
  const { palette } = useTheme()

  const { t } = useTranslation()

  const [deepLink, setDeepLink] = useState('')

  const isMobileDevice = useMemo(() => {
    return isMobile()
  }, [])

  const subscribeToResponse = useCallback(async (id: string, jwt: string) => {
    let response: {
      zkProof: string
      nullifier: string
    } | null = null
    do {
      try {
        const { data } = await api.get<{ callback_data: string }>(
          `${ApiServicePaths.VerifyProxy}/v1/public/verify/response/${id}`,
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          },
        )

        try {
          response = JSON.parse(JSON.parse(data.callback_data))
        } catch (error) {
          response = JSON.parse(data.callback_data)
        }
      } catch (error) {
        await sleep(5_000)
      }

      if (response) {
        break
      }
    } while (!response)

    return response
  }, [])

  const buildAuthRequest = useCallback(async () => {
    const { data } = await api.post<{
      verification_id: string
      jwt: string
    }>(`${ApiServicePaths.VerifyProxy}/v1/public/verify/request`, {
      body: {
        data: {
          type: 'verification_id',
          attributes: {
            request_data: JSON.stringify({}),
          },
        },
      },
    })

    const newWallet = ethers.Wallet.createRandom()

    const newUrl = new URL('unitedgsh://external')
    newUrl.searchParams.append('id', data.verification_id)
    newUrl.searchParams.append('type', 'authorize')
    newUrl.searchParams.append('eth_address', newWallet.address)
    newUrl.searchParams.append(
      'callback_url',
      `${config.API_URL}${ApiServicePaths.VerifyProxy}/v1/public/verify/callback/${data.verification_id}`,
    )

    const deepLink = newUrl.href

    return { pk: newWallet.privateKey, deepLink, id: data.verification_id, jwt: data.jwt }
  }, [])

  const login = useCallback(async () => {
    try {
      const { pk, deepLink, id, jwt } = await buildAuthRequest()

      setDeepLink(deepLink)

      const response = await subscribeToResponse(id, jwt)

      identityStore.setZkProofJson(JSON.stringify(response.zkProof))
      identityStore.setPrivateKey(pk)
      identityStore.setNullifier(response.nullifier)

      await web3Store.init('united-space')

      setDeepLink('')
    } catch (error) {
      ErrorHandler.process(error)
      identityStore.clear()
    }
  }, [buildAuthRequest, subscribeToResponse])

  return (
    <>
      {trigger(login)}

      <Dialog
        open={!!deepLink}
        onClose={() => setDeepLink('')}
        aria-labelledby='united-space-auth-modal-title'
        aria-describedby='united-space-auth-modal-description'
        scroll='body'
        sx={{
          '& .MuiDialog-paper': {
            background: 'transparent',
          },
        }}
      >
        <Stack
          gap={4}
          sx={{
            maxWidth: '576px',
            width: '100%',
          }}
        >
          <Card>
            <Stack
              sx={{
                maxWidth: '528px',
                width: isMobileDevice ? '100%' : '528px',
              }}
              alignItems='center'
              justifyContent='center'
            >
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                width='100%'
              >
                <Typography variant='h6' color={palette.text.primary}>
                  {t('united-space-login.modal-title')}
                </Typography>
                <IconButton onClick={() => setDeepLink('')}>
                  <UiIcon name={Icons.Close} size={5} />
                </IconButton>
              </Stack>
              <Divider sx={{ my: 6, width: '100%' }} />
              {isMobileDevice ? (
                <DeepLinkConfirmation
                  deepLink={deepLink}
                  caption={t('united-space-login.confirm-action-caption')}
                  buttonText={t('united-space-login.open-app-btn')}
                />
              ) : (
                <QRCodeDisplay
                  deepLink={deepLink}
                  caption={t('united-space-login.under-qr-caption')}
                />
              )}
            </Stack>
          </Card>

          <Card sx={{ p: 0 }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<UiIcon name={Icons.CarretDown} color={palette.text.secondary} />}
                aria-controls='panel1-content'
                id='panel1-header'
                sx={{
                  px: 4,
                  py: 3,
                }}
              >
                <Stack direction='row' alignItems='center' gap={4}>
                  <UiIcon name={Icons.Question} size={5} />
                  <Typography variant='subtitle4' color={palette.text.primary}>
                    {t('united-space-login.accordion-title')}
                  </Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  px: 4,
                  py: 3,
                  pb: 0,
                }}
              >
                <Stack gap={6}>
                  <Stack gap={5}>
                    <AccordionBodyTitle
                      number='01.'
                      title={t('united-space-login.accordion-body-title-1')}
                    />
                    <AccordionBodyDesc desc={t('united-space-login.accordion-body-desc-1')} />

                    <AppBadges />

                    <Stack
                      direction='row'
                      component='a'
                      href={config.UNITEDSPACE_WEBSITE_LINK}
                      target='_blank'
                      alignItems='center'
                      gap={2}
                    >
                      <Typography
                        variant='body4'
                        color={palette.text.secondary}
                        sx={{
                          textDecoration: 'underline',
                        }}
                      >
                        {t('united-space-login.accordion-body-learn-more-link')}
                      </Typography>

                      <UiIcon name={Icons.ArrowSquareOut} size={4} color={palette.text.secondary} />
                    </Stack>
                  </Stack>

                  <Divider />

                  <Stack gap={5}>
                    <AccordionBodyTitle
                      number='02.'
                      title={t('united-space-login.accordion-body-title-2')}
                    />
                    <AccordionBodyDesc desc={t('united-space-login.accordion-body-desc-2')} />
                  </Stack>

                  <Divider />

                  <Stack gap={5} pb={6}>
                    <AccordionBodyTitle
                      number='03.'
                      title={t('united-space-login.accordion-body-title-3')}
                    />
                    <AccordionBodyDesc desc={t('united-space-login.accordion-body-desc-3')} />
                  </Stack>
                </Stack>
              </AccordionDetails>
            </Accordion>
          </Card>
        </Stack>
      </Dialog>
    </>
  )
}

function AccordionBodyTitle({ title, number }: { title: string; number: string }) {
  const { palette } = useTheme()

  return (
    <Stack gap={6} direction='row'>
      <Typography variant='subtitle4' color={palette.text.secondary}>
        {number}
      </Typography>

      <Typography variant='subtitle4' color={palette.text.primary}>
        {title}
      </Typography>
    </Stack>
  )
}

function AccordionBodyDesc({ desc }: { desc: string }) {
  const { palette } = useTheme()

  return (
    <Typography variant='body4' color={palette.text.secondary}>
      {desc}
    </Typography>
  )
}
