import { Button, Stack, Typography, useTheme } from '@mui/material'

import { Icons } from '@/enums'
import { UiIcon } from '@/ui'

type DeepLinkConfirmationProps = {
  deepLink: string
  caption: string
  buttonText: string
}

export function DeepLinkConfirmation({ deepLink, caption, buttonText }: DeepLinkConfirmationProps) {
  const { spacing, palette } = useTheme()

  return (
    <Stack direction='column' alignItems='center' gap={spacing(6)}>
      <UiIcon name={Icons.UnitedSpaceApp} size={20} />
      <Typography variant='subtitle2' color={palette.text.primary} align='center'>
        {caption}
      </Typography>
      <Button size='large' href={deepLink}>
        {buttonText}
      </Button>
    </Stack>
  )
}
