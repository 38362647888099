import { useTranslation } from 'react-i18next'
import { setLocale } from 'yup'

export const useLocalisedYupScheme = () => {
  const { t } = useTranslation()

  setLocale({
    mixed: {
      required: t('validations.field-error-required'),
      notType: ({ type }) => {
        if (type === 'number') return t('validations.field-error-number-type-mismatch')
        return t('validations.field-error-type-mismatch', { type })
      },
    },
    string: {
      min: ({ min }) => t('validations.field-error-min-length', { min }),
      max: ({ max }) => t('validations.field-error-max-length', { max }),
    },
    number: {
      moreThan: ({ more }) => t('validations.field-error-more-than', { more }),
    },
  })
}
