import { alpha } from '@mui/material'
import { PaletteOptions } from '@mui/material/styles'

export const lightPalette: PaletteOptions = {
  mode: 'light',
  common: {
    black: '#242834', // base/black
    white: '#ffffff', // base/white
  },
  primary: {
    darker: '#613aec', // primary/primary-darker
    dark: '#6d47f4', // primary/primary-dark
    main: '#7e5aff', // primary/primary-base
    light: '#7e5aff1a', // primary/primary-light
    lighter: '#7e5aff0d', // primary/primary-lighter
    contrastText: '#000000',
  },
  secondary: {
    darker: '#71d601', // secondary/secondary-darker
    dark: '#7dea04', // secondary/secondary-dark
    main: '#86ff00', // secondary/secondary-base
    light: '#86ff001a', // secondary/secondary-light
    lighter: '#86ff000d', // secondary/secondary-lighter
    contrastText: '#000000',
  },
  success: {
    darker: '#058e36', // success/success-darker
    dark: '#059b3b', // success/success-dark
    main: '#00a93c', // success/success-base
    light: alpha('#00a93c', 0.1), // success/success-light
    lighter: alpha('#00a93c', 0.05), // success/green-lighter
    contrastText: '#ffffff',
  },
  error: {
    darker: '#b32016', // error/error-darker
    dark: '#c5291f', // error/error-dark
    main: '#d02d23', // error/error-base
    light: alpha('#d02d23', 0.1), // error/error-light
    lighter: alpha('#d02d23', 0.05), // error/red-lighter
    contrastText: '#ffffff',
  },
  warning: {
    darker: '#d9590f', // warning/warning-darker
    dark: '#e55f12', // warning/warning-dark
    main: '#f96815', // warning/warning-base
    light: alpha('#d02d23', 0.1), // warning/warning-light
    lighter: alpha('#d02d23', 0.05), // warning/warning-lighter
    contrastText: '#ffffff',
  },
  text: {
    primary: '#242834', // text & icons/primary
    secondary: alpha('#242834', 0.56), // text & icons/secondary
    placeholder: alpha('#242834', 0.44), // text & icons/placeholder
    disabled: alpha('#242834', 0.28), // text & icons/disabled
  },
  action: {
    active: alpha('#242834', 0.05), // background/component/primary
    hover: alpha('#242834', 0.1), // background/component/hovered
    focus: alpha('#242834', 0.15), // background/component/pressed
    selected: alpha('#242834', 0.05), // background/component/selected
    disabled: alpha('#242834', 0.05), // background/component/disabled
  },
  background: {
    default: '#ffffff', // background/bg/primary
    light: '#f5f5f7', // background/bg/Canvas
    paper: '#ffffff', // background/bg/pure
  },
  divider: alpha('#242834', 0.05),
  additional: {
    layerBorder: '#ffffff', // additional/layer-border
    pureBlack: '#242834', // additional/pure-black
    gradient: 'linear-gradient(90deg, #00A93C 0%, #F99E15 100%)', // additional/gradient
  },
}

export const darkPalette: PaletteOptions = {
  mode: 'dark',
  common: {
    black: '#202020', // base/black
    white: '#ffffff', // base/white
  },
  primary: {
    darker: '#a68efa', // primary/primary-darker
    dark: '#9277f3', // primary/primary-dark
    main: '#8262f4', // primary/primary-base
    light: alpha('#8262f4', 0.1), // primary/primary-light
    lighter: alpha('#8262f4', 0.05), // primary/primary-lighter
    contrastText: '#000000',
  },
  secondary: {
    darker: '#9fe353', // secondary/secondary-darker
    dark: '#95de44', // secondary/secondary-dark
    main: '#86D82B', // secondary/secondary-base
    light: alpha('#86D82B', 0.1), // secondary/secondary-light
    lighter: alpha('#86D82B', 0.05), // secondary/secondary-lighter
    contrastText: '#1d2715',
  },
  success: {
    darker: '#35d970', // success/success-darker
    dark: '#1fc75a', // success/success-dark
    main: '#14b94f', // success/success-base
    light: alpha('#14b94f', 0.1), // success/success-light
    lighter: alpha('#14b94f', 0.05), // success/green-lighter
    contrastText: '#ffffff',
  },
  error: {
    darker: '#e95e55', // error/error-darker
    dark: '#e05047', // error/error-dark
    main: '#d54037',
    light: alpha('#d54037', 0.1), // error/error-light
    lighter: alpha('#d54037', 0.05), // error/red-lighter
    contrastText: '#ffffff',
  },
  warning: {
    darker: '#f98848', // warning/warning-darker
    dark: '#f87e39', // warning/warning-dark
    main: '#f77226', // warning/warning-base
    light: alpha('#f77226', 0.1), // warning/warning-light
    lighter: alpha('#f77226', 0.05), // warning/warning-lighter
    contrastText: '#ffffff',
  },
  text: {
    primary: alpha('#ffffff', 0.9), // text & icons/primary
    secondary: alpha('#ffffff', 0.56), // text & icons/secondary
    placeholder: alpha('#ffffff', 0.44), // text & icons/placeholder
    disabled: alpha('#ffffff', 0.28), // text & icons/disabled
  },
  action: {
    active: alpha('#ffffff', 0.05), // background/component/primary
    hover: alpha('#ffffff', 0.1), // background/component/hovererd
    focus: alpha('#ffffff', 0.15), // background/component/pressed
    selected: alpha('#ffffff', 0.05), // background/component/selected
    disabled: alpha('#ffffff', 0.05), // background/component/disabled
  },
  background: {
    default: '#272727', // background/bg/primary
    light: '#111111', // background/bg/Canvas
    paper: '#353535', // background/bg/pure
  },
  divider: alpha('#ffffff', 0.05),
  additional: {
    layerBorder: alpha('#ffffff', 0.05), // additional/layer-border
    pureBlack: '#2b2f3b', // additional/pure-black
    gradient: 'linear-gradient(90deg, #00A93C 0%, #F99E15 100%)', // additional/gradient
  },
}
