import { Box, BoxProps, useTheme } from '@mui/material'
import { ComponentProps } from 'react'

import UiIcon from '@/ui/UiIcon'

type Props = {
  iconProps: ComponentProps<typeof UiIcon>
} & BoxProps

export default function UiCircledBadge({ iconProps, ...rest }: Props) {
  const { palette } = useTheme()

  return (
    <Box
      {...rest}
      sx={{
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: palette.success.light,
        ...rest.sx,
      }}
    >
      <UiIcon {...iconProps} />
    </Box>
  )
}
