import { config } from '@config'
import {
  Button,
  Card,
  Dialog,
  Divider,
  IconButton,
  Stack,
  StackProps,
  Typography,
  useTheme,
} from '@mui/material'
import isEmpty from 'lodash/isEmpty'
import { PropsWithChildren, ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { api } from '@/api/clients'
import { DeepLinkConfirmation } from '@/common/DeepLinkConfirmation'
import { QRCodeDisplay } from '@/common/QRCodeDisplay'
import { ApiServicePaths, Icons } from '@/enums'
import { ErrorHandler, isMobile, sleep } from '@/helpers'
import { useWeb3State } from '@/store'
import { UiAmountField, UiCircledBadge, UiIcon } from '@/ui'

enum TopUpResponseStatus {
  Success = 'success',
  ErrorInsufficientFunds = 'error_insufficient_funds',
  UnknownAccount = 'unknown_account',
  ErrorUnknown = 'error_unknown',
}

type Props = {
  trigger?: (startCb: () => Promise<void>) => ReactElement
  isInitiallyOpened?: boolean
} & PropsWithChildren

export default function UnitedSpaceTopUp({ trigger, isInitiallyOpened }: Props) {
  const { palette, breakpoints } = useTheme()

  const { t } = useTranslation()

  const { provider } = useWeb3State()

  const [isModelShown, setIsModelShown] = useState(false)

  const [deepLink, setDeepLink] = useState('')
  const [topUpResponseStatus, setTopUpResponseStatus] = useState<TopUpResponseStatus | null>(null)

  const [amount, setAmount] = useState('')

  const isMobileDevice = useMemo(() => {
    return isMobile()
  }, [])

  const subscribeToResponse = useCallback(async (id: string, jwt: string) => {
    let response: { status: TopUpResponseStatus } | null = null

    do {
      try {
        const { data } = await api.get<{ callback_data: string }>(
          `${ApiServicePaths.VerifyProxy}/v1/public/verify/response/${id}`,
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          },
        )

        if (isEmpty(data)) throw new Error('Empty response')

        response = JSON.parse(JSON.parse(data.callback_data))
      } catch (error) {
        await sleep(5_000)
      }

      if (response && response.status) {
        break
      }
    } while (!response)

    return response
  }, [])

  const buildTopUpRequest = useCallback(
    async (amount: number) => {
      if (!provider.address) throw new Error('provider not initialized')

      const { data } = await api.post<{
        verification_id: string
        jwt: string
      }>(`${ApiServicePaths.VerifyProxy}/v1/public/verify/request`, {
        body: {
          data: {
            type: 'verification_id',
            attributes: {
              request_data: JSON.stringify({}),
            },
          },
        },
      })

      const newUrl = new URL('unitedgsh://external')
      newUrl.searchParams.append('type', 'withdraw')
      newUrl.searchParams.append('amount', amount.toString())
      newUrl.searchParams.append('address', provider.address)
      newUrl.searchParams.append(
        'callback_url',
        `${config.API_URL}${ApiServicePaths.VerifyProxy}/v1/public/verify/callback/${data.verification_id}`,
      )

      return { deepLink: newUrl.href, id: data.verification_id, jwt: data.jwt }
    },
    [provider.address],
  )

  const submit = useCallback(async () => {
    const amountToTopUp = Number(amount)

    if (isNaN(amountToTopUp) || amountToTopUp <= 0) return

    try {
      const { deepLink, id, jwt } = await buildTopUpRequest(amountToTopUp)

      setDeepLink(deepLink)

      const response = await subscribeToResponse(id, jwt)

      if (!response?.status) throw new Error('Unknown error')

      setTopUpResponseStatus(response.status)
    } catch (error) {
      setTopUpResponseStatus(TopUpResponseStatus.ErrorUnknown)
      ErrorHandler.process(error)
    }
  }, [amount, buildTopUpRequest, subscribeToResponse])

  const reset = useCallback(() => {
    setDeepLink('')
    setAmount('')
    setTopUpResponseStatus(null)
  }, [])

  const handleOpenModal = useCallback(() => {
    setIsModelShown(true)
    setTopUpResponseStatus(null)
  }, [])

  const handleCloseModal = useCallback(() => {
    setIsModelShown(false)
    reset()
  }, [reset])

  useEffect(() => {
    if (isInitiallyOpened) {
      handleOpenModal()
    }
  }, [isInitiallyOpened, handleOpenModal])

  return (
    <>
      {trigger ? trigger(async () => handleOpenModal()) : null}

      <Dialog
        open={isModelShown}
        onClose={() => handleCloseModal()}
        aria-labelledby='united-space-auth-modal-title'
        aria-describedby='united-space-auth-modal-description'
        scroll='body'
        sx={{
          '& .MuiDialog-paper': {
            background: 'transparent',
          },
        }}
      >
        <Stack
          gap={4}
          sx={{
            width: '480px',
            [breakpoints.down('sm')]: { width: 'auto', minWidth: '280px' },
          }}
        >
          <Card>
            <Stack width='100%' alignItems='center' justifyContent='center'>
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                width='100%'
              >
                <Stack gap={2} alignItems='center' direction='row'>
                  {deepLink && !topUpResponseStatus && (
                    <IconButton onClick={reset}>
                      <UiIcon name={Icons.CaretLeft} size={5} />
                    </IconButton>
                  )}
                  <Typography variant='h6' color={palette.text.primary}>
                    {t('united-space-top-up.modal-title')}
                  </Typography>
                </Stack>
                <IconButton onClick={() => handleCloseModal()}>
                  <UiIcon name={Icons.Close} size={5} />
                </IconButton>
              </Stack>
              <Divider sx={{ my: 6, width: '100%' }} />

              {deepLink ? (
                <>
                  {topUpResponseStatus ? (
                    {
                      [TopUpResponseStatus.Success]: (
                        <TopUpResponseStatusSuccess onClose={handleCloseModal} />
                      ),
                      [TopUpResponseStatus.ErrorInsufficientFunds]: (
                        <TopUpResponseStatusErrorInsufficientFunds onClose={handleCloseModal} />
                      ),
                      [TopUpResponseStatus.UnknownAccount]: (
                        <TopUpResponseStatusUnknownAccount onClose={handleCloseModal} />
                      ),
                      [TopUpResponseStatus.ErrorUnknown]: (
                        <TopUpResponseStatusErrorUnknown onClose={handleCloseModal} />
                      ),
                    }[topUpResponseStatus]
                  ) : isMobileDevice ? (
                    <DeepLinkConfirmation
                      deepLink={deepLink}
                      caption={t('united-space-top-up.confirm-action-caption')}
                      buttonText={t('united-space-top-up.open-app-btn')}
                    />
                  ) : (
                    <QRCodeDisplay
                      deepLink={deepLink}
                      caption={t('united-space-top-up.under-qr-caption')}
                    />
                  )}
                </>
              ) : (
                <Stack gap={6} alignItems='center' width='100%'>
                  <Typography
                    variant='body3'
                    color={palette.text.secondary}
                    alignSelf='center'
                    textAlign='center'
                  >
                    {t('united-space-top-up.description')}
                  </Typography>
                  <Stack gap={4} width='100%'>
                    <UiAmountField
                      name='top-up-field'
                      value={amount}
                      onChange={v => setAmount(v)}
                      label={t('united-space-top-up.amount-placeholder')}
                      sx={{ maxWidth: 450 }}
                      snapPoints={[10, 50, 100, 200]}
                      step={1}
                    />
                    <Button onClick={submit}>{t('united-space-top-up.submit-btn')}</Button>
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Card>
        </Stack>
      </Dialog>
    </>
  )
}

type TopUpResponseStatusProps = {
  onClose: () => void
} & StackProps

function TopUpResponseStatusSuccess({ onClose, ...rest }: TopUpResponseStatusProps) {
  const { palette, spacing } = useTheme()

  const { t } = useTranslation()

  return (
    <Stack {...rest} gap={6} alignItems='center' textAlign='center' width='100%'>
      <UiCircledBadge
        iconProps={{
          name: Icons.Check,
          size: 6,
          color: palette.success.dark,
        }}
        sx={{
          width: spacing(14),
          height: spacing(14),
        }}
      />
      <Typography variant='subtitle3' color={palette.text.primary}>
        {t('united-space-top-up.success-msg')}
      </Typography>

      <Button
        onClick={() => {
          onClose()
        }}
        sx={{
          width: '100%',
        }}
      >
        {t('united-space-top-up.okay-btn')}
      </Button>
    </Stack>
  )
}

function TopUpResponseStatusErrorInsufficientFunds({ onClose, ...rest }: TopUpResponseStatusProps) {
  const { palette, spacing } = useTheme()

  const { t } = useTranslation()

  return (
    <Stack {...rest} gap={6} alignItems='center' textAlign='center' width='100%'>
      <UiCircledBadge
        iconProps={{
          name: Icons.Wallet,
          size: 6,
          color: palette.error.dark,
        }}
        sx={{
          width: spacing(14),
          height: spacing(14),
          background: palette.error.light,
        }}
      />
      <Typography variant='subtitle3' color={palette.text.primary}>
        {t('united-space-top-up.insufficient-funds-msg')}
      </Typography>

      <Button
        onClick={() => {
          onClose()
        }}
        sx={{
          width: '100%',
        }}
      >
        {t('united-space-top-up.try-again-btn')}
      </Button>
    </Stack>
  )
}

function TopUpResponseStatusUnknownAccount({ onClose, ...rest }: TopUpResponseStatusProps) {
  const { palette, spacing } = useTheme()

  const { t } = useTranslation()

  return (
    <Stack {...rest} gap={6} alignItems='center' textAlign='center' width='100%'>
      <UiCircledBadge
        iconProps={{
          name: Icons.Question,
          size: 6,
          color: palette.warning.dark,
        }}
        sx={{
          width: spacing(14),
          height: spacing(14),
          background: palette.warning.light,
        }}
      />
      <Typography variant='subtitle3' color={palette.text.primary}>
        {t('united-space-top-up.unknown-account-msg')}
      </Typography>

      <Button
        onClick={() => {
          onClose()
        }}
        sx={{
          width: '100%',
        }}
      >
        {t('united-space-top-up.okay-btn')}
      </Button>
    </Stack>
  )
}

function TopUpResponseStatusErrorUnknown({ onClose, ...rest }: TopUpResponseStatusProps) {
  const { palette, spacing } = useTheme()

  const { t } = useTranslation()

  return (
    <Stack {...rest} gap={6} alignItems='center' textAlign='center' width='100%'>
      <UiCircledBadge
        iconProps={{
          name: Icons.Close,
          size: 6,
          color: palette.error.dark,
        }}
        sx={{
          width: spacing(14),
          height: spacing(14),
          background: palette.error.light,
        }}
      />
      <Typography variant='subtitle3' color={palette.text.primary}>
        {t('united-space-top-up.unknown-error-msg')}
      </Typography>

      <Button
        variant='outlined'
        onClick={() => {
          onClose()
        }}
        sx={{
          width: '100%',
        }}
      >
        {t('united-space-top-up.okay-btn')}
      </Button>
    </Stack>
  )
}
