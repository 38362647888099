import { config } from '@config'
import {
  Chain,
  CHAIN_TYPES,
  ChainId,
  EthTransactionResponse,
  getEthExplorerAddressUrl,
  getEthExplorerTxUrl,
  hexToDecimal,
  PROVIDER_EVENT_BUS_EVENTS,
  PROVIDER_EVENTS,
  ProviderEventBus,
  ProviderProxy,
  PROVIDERS,
  RawProvider,
  SolanaTransactionResponse,
  TransactionResponse,
  TxRequestBody,
} from '@distributedlab/w3p'
import type { TransactionRequest } from '@ethersproject/abstract-provider'
import type { Deferrable } from '@ethersproject/properties'
import { providers, Wallet } from 'ethers'
import { isHexString } from 'ethers/lib/utils'

import { NETWORK_NAME, networkConfigsMap } from '@/constants/network-config'
import { createStore } from '@/helpers'
import { RarimarketAccountFactory__factory } from '@/types'

type AuthState = {
  privateKey: string
  zkProofJson: string
  nullifier: string
}

const netConf = networkConfigsMap[NETWORK_NAME]

class CustomProvider extends providers.JsonRpcProvider {
  constructor() {
    super(netConf.rpcUrl, {
      name: netConf.name,
      chainId: netConf.chainId,
    })
  }

  detectNetwork(): Promise<providers.Network> {
    return Promise.resolve({
      chainId: netConf.chainId,
      name: netConf.name,
    })
  }
}

const [identityStore, useIdentityState] = createStore(
  'identity',
  {
    privateKey: '',
    zkProofJson: '',
    nullifier: '',
  } as AuthState,
  state => ({
    get address(): string | null {
      return isHexString(state.privateKey, 32) ? new Wallet(state.privateKey).address : null
    },
  }),
  state => ({
    setPrivateKey: (privateKey: string) => {
      state.privateKey = privateKey
    },
    setZkProofJson: (zkProofJson: string) => {
      state.zkProofJson = zkProofJson
    },
    setNullifier: (nullifier: string) => {
      state.nullifier = nullifier
    },

    getIdentityWeb3Wallet: (privateKey: string) => {
      return new Wallet(privateKey, new CustomProvider())
    },

    clear: () => {
      state.privateKey = ''
      state.zkProofJson = ''
      state.nullifier = ''
    },
  }),
  { persistProperties: ['privateKey', 'zkProofJson', 'nullifier'] },
)

export { identityStore, useIdentityState }

export class UnitedSpaceProvider extends ProviderEventBus implements ProviderProxy {
  readonly wallet: Wallet
  rawProvider: RawProvider
  nullifier: string

  chainId?: ChainId
  address?: string

  constructor(provider: RawProvider) {
    super()

    const { wallet, nullifier } = provider as unknown as {
      wallet: Wallet
      nullifier: string
    }

    this.wallet = wallet
    this.nullifier = nullifier

    this.rawProvider = wallet as unknown as RawProvider
  }

  static get providerType(): PROVIDERS {
    return 'united-space' as PROVIDERS
  }

  get chainType(): CHAIN_TYPES {
    return CHAIN_TYPES.EVM
  }

  get isConnected(): boolean {
    return Boolean(this.chainId && this.address)
  }

  get defaultEventPayload() {
    return {
      address: this.address,
      chainId: this.chainId,
      isConnected: this.isConnected,
    }
  }

  get rarimarketAccountFactoryContractInstance() {
    return RarimarketAccountFactory__factory.connect(
      config.RARIMARKET_ACCOUNT_FACTORY,
      this.wallet.provider,
    )
  }

  async getMarketAccount() {
    return this.rarimarketAccountFactoryContractInstance.getAbstractAccount(this.nullifier)
  }

  async init(): Promise<void> {
    await this.setListeners()
    const network = await this.wallet.provider.getNetwork()
    this.address = await this.getMarketAccount()

    this.chainId = hexToDecimal(network.chainId as ChainId)

    this.emit(PROVIDER_EVENT_BUS_EVENTS.Initiated, this.defaultEventPayload)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async switchChain(chainId: ChainId): Promise<void> {
    throw new Error('Method not implemented.')
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async addChain(chain: Chain): Promise<void> {
    throw new Error('Method not implemented.')
  }

  async connect(): Promise<void> {}

  async disconnect(): Promise<void> {
    identityStore.clear()
  }

  getAddressUrl(chain: Chain, address: string): string {
    return getEthExplorerAddressUrl(chain, address)
  }

  getTxUrl(chain: Chain, txHash: string): string {
    return getEthExplorerTxUrl(chain, txHash)
  }

  getHashFromTx(txResponse: TransactionResponse): string {
    return (txResponse as EthTransactionResponse).transactionHash as SolanaTransactionResponse
  }

  async signAndSendTx(tx: TxRequestBody): Promise<TransactionResponse> {
    this.emit(PROVIDER_EVENT_BUS_EVENTS.BeforeTxSent, {
      txBody: tx,
    })
    const transactionResponse = await this.wallet.sendTransaction(
      tx as Deferrable<TransactionRequest>,
    )

    this.emit(PROVIDER_EVENT_BUS_EVENTS.TxSent, {
      txHash: transactionResponse.hash,
    })

    const receipt = await transactionResponse.wait()

    this.emit(PROVIDER_EVENT_BUS_EVENTS.TxConfirmed, {
      txResponse: receipt,
    })

    return receipt
  }

  async signMessage(message: string): Promise<string> {
    return this.wallet.signMessage(message)
  }

  async setListeners() {
    const stubProvider = this.wallet.provider as providers.BaseProvider

    stubProvider.on(PROVIDER_EVENTS.AccountsChanged, async () => {
      this.address = await this.getMarketAccount()

      this.emit(PROVIDER_EVENT_BUS_EVENTS.AccountChanged, this.defaultEventPayload)
      this.emit(
        this.isConnected ? PROVIDER_EVENT_BUS_EVENTS.Connect : PROVIDER_EVENT_BUS_EVENTS.Disconnect,
        this.defaultEventPayload,
      )
    })

    stubProvider.on(PROVIDER_EVENTS.ChainChanged, (chainId: ChainId) => {
      this.chainId = hexToDecimal(chainId)

      this.emit(PROVIDER_EVENT_BUS_EVENTS.ChainChanged, this.defaultEventPayload)
    })
  }
}
