import { useCallback, useMemo } from 'react'

import { config } from '@/config'
import { useWeb3State } from '@/store'
import { ConditionalToken__factory } from '@/types/contracts'

export const useConditionalToken = (address = config.CONDITIONAL_TOKEN_CONTRACT) => {
  const { provider, address: userAddr, contractConnector } = useWeb3State()

  const contractInterface = useMemo(() => ConditionalToken__factory.createInterface(), [])

  const contractInstance = useMemo(() => {
    if (!address || !contractConnector) return null

    return ConditionalToken__factory.connect(address, contractConnector)
  }, [address, contractConnector])

  const setApprovalForAll = useCallback(
    (spender: string) => {
      if (!contractInstance || !address) throw new ReferenceError('Contract issue')

      const data = contractInterface.encodeFunctionData('setApprovalForAll', [spender, true])

      const txBody = {
        to: address,
        data,
      }
      return provider.signAndSendTx?.(txBody)
    },
    [contractInstance, address, provider, contractInterface],
  )

  const getIsApprovedForAll = useCallback(
    (spender: string) => {
      if (!contractInstance || !userAddr) throw new ReferenceError('Contract is missing')

      return contractInstance.isApprovedForAll(userAddr, spender)
    },
    [contractInstance, userAddr],
  )

  const getBalanceOfBatch = useCallback(
    (tokenIds: string[]) => {
      if (!contractInstance || !userAddr) throw new ReferenceError('Contract is missing')
      return contractInstance.balanceOfBatch(
        Array.from<string>({ length: tokenIds.length }).fill(userAddr),
        tokenIds,
      )
    },
    [contractInstance, userAddr],
  )

  return {
    setApprovalForAll,
    getIsApprovedForAll,
    getBalanceOfBatch,
    contractInstance,
  }
}
