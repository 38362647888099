export enum ApiServicePaths {
  Auth = '/integrations/geo-auth-svc',
  OrderBook = '/integrations/geo-market-orderbook-svc',
  VerifyProxy = '/integrations/united-space-verify-proxy',
}

export enum ApiSortOrder {
  Ascending = 'asc',
  Descending = 'desc',
}
