import { Chain, CHAIN_TYPES } from '@distributedlab/w3p'
import { utils } from 'ethers'

import { config } from '@/config'

export type NetworkName = 'mainnet' | 'testnet'

export interface NetworkConfig {
  chainId: number
  name: string
  networkName: NetworkName
  rpcUrl: string
  explorerUrl: string
}

export const networkConfigsMap: Record<NetworkName, NetworkConfig> = {
  mainnet: {
    chainId: 201411,
    name: 'Mainnet',
    networkName: 'mainnet',
    rpcUrl: 'https://rpc.evm.mainnet.rarimo.com',
    explorerUrl: 'https://evmscan.rarimo.com',
  },
  testnet: {
    chainId: 35443,
    name: 'Q Testnet',
    networkName: 'testnet',
    rpcUrl: 'https://rpc.qtestnet.org',
    explorerUrl: 'https://explorer.qtestnet.org',
  },
}

export const chainIdToNetworkMap: { [key: string]: NetworkName } = {
  201411: 'mainnet',
  35443: 'testnet',
}

export const connectorParametersMap = Object.values(networkConfigsMap).reduce(
  (acc, config) => {
    const token =
      config.chainId === 201411
        ? {
            name: 'Ethereum',
            symbol: 'ETH',
            decimals: 18,
          }
        : {
            name: 'Q',
            symbol: 'Q',
            decimals: 18,
          }
    acc[config.chainId] = {
      id: utils.hexlify(config.chainId),
      name: config.name,
      rpcUrl: config.rpcUrl,
      explorerUrl: config.explorerUrl,
      type: CHAIN_TYPES.EVM,
      token,
      icon: '',
    }
    return acc
  },
  {} as { [key: string]: Chain },
)

export const NETWORK_NAME = (config.NETWORK || 'testnet') as NetworkName
