import { Button, Dialog, DialogProps, Stack, TextField, useTheme } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'

import { config } from '@/config'
import { ErrorHandler } from '@/helpers'
import { useCollateralToken, useConditionalToken } from '@/hooks'
import { useWeb3State } from '@/store'
import { UiDialogContent, UiDialogTitle } from '@/ui'

// TODO: temp dev solution, to be removed
export default function Web3DetailsModal(props: DialogProps) {
  const { spacing } = useTheme()
  const { address } = useWeb3State()
  const [amount, setAmount] = useState('' as string)
  const [isCollateralApproved, setIsCollateralApproved] = useState(false)
  const [isConditionalApproved, setIsConditionalApproved] = useState(false)
  const [isApprovingCollateral, setIsApprovingCollateral] = useState(false)
  const [isApprovingConditional, setIsApprovingConditional] = useState(false)

  const [isMinting, setIsMinting] = useState(false)

  const { getIsApprovedForAll, setApprovalForAll } = useConditionalToken()
  const { getIsApproved, approve, mint } = useCollateralToken()

  const checkIsApproved = useCallback(async () => {
    const isAdapterApproved = await getIsApproved(config.ADAPTER_CONTRACT)
    const isExchangeApproved = await getIsApproved(config.CTF_EXCHANGE_CONTRACT)
    const isCondAdapterApproved = await getIsApprovedForAll(config.ADAPTER_CONTRACT)
    const isCondACtfApproved = await getIsApprovedForAll(config.CTF_EXCHANGE_CONTRACT)
    setIsCollateralApproved(isAdapterApproved && isExchangeApproved)
    setIsConditionalApproved(isCondAdapterApproved && isCondACtfApproved)
  }, [getIsApproved, getIsApprovedForAll])

  const mintCollateral = useCallback(async () => {
    if (!address) return
    try {
      setIsMinting(true)
      await mint(amount)
      setIsMinting(false)
    } catch (e) {
      setIsMinting(false)
      ErrorHandler.process('Error minting collateral')
    }
  }, [address, mint, amount])

  useEffect(() => {
    address && checkIsApproved()
  }, [checkIsApproved, address])

  const handleApproveCollateral = async () => {
    if (!address) return
    try {
      setIsApprovingCollateral(true)
      await approve(config.ADAPTER_CONTRACT)
      await approve(config.CTF_EXCHANGE_CONTRACT)
      setTimeout(() => {
        checkIsApproved()
        setIsApprovingCollateral(false)
      }, 1000)
    } catch (e) {
      setIsApprovingCollateral(false)
      ErrorHandler.process('Error approving collateral')
    }
  }

  const handleApproveConditional = async () => {
    if (!address) return
    try {
      setIsApprovingConditional(true)
      await setApprovalForAll(config.ADAPTER_CONTRACT)
      await setApprovalForAll(config.CTF_EXCHANGE_CONTRACT)
      setTimeout(() => {
        checkIsApproved()
        setIsApprovingConditional(false)
      }, 1000)
    } catch (e) {
      setIsApprovingConditional(false)
      ErrorHandler.process('Error approving conditional')
    }
  }

  return (
    <Dialog
      {...props}
      PaperProps={{
        ...props.PaperProps,
        sx: { width: spacing(110) },
      }}
    >
      <UiDialogTitle onClose={props.onClose}>Web3 details</UiDialogTitle>
      <UiDialogContent>
        <Stack spacing={6}>
          <Button
            disabled={isApprovingCollateral || isCollateralApproved}
            onClick={() => handleApproveCollateral()}
          >
            {isCollateralApproved ? 'Collateral Approved' : 'Approve Collateral'}
          </Button>
          <Button
            disabled={isApprovingConditional || isConditionalApproved}
            onClick={() => handleApproveConditional()}
          >
            {isConditionalApproved ? 'Conditional Approved' : 'Approve Conditional'}
          </Button>
          <Stack spacing={2}>
            <TextField
              label='Amount'
              placeholder='Specify mint amount'
              disabled={isMinting}
              value={amount}
              onChange={e => setAmount(e.target.value)}
            />
            <Button disabled={isMinting} onClick={mintCollateral}>
              Mint Collaterals
            </Button>
          </Stack>
        </Stack>
      </UiDialogContent>
    </Dialog>
  )
}
