import { lazy, Suspense, useCallback } from 'react'
import {
  createBrowserRouter,
  LoaderFunctionArgs,
  Navigate,
  Outlet,
  redirect,
  RouterProvider,
} from 'react-router-dom'

import { FallbackComponent } from '@/App'
import { RoutePaths } from '@/enums'

import MainLayout from './layouts/MainLayout'
import { useAuthState } from './store'

export const AppRoutes = () => {
  const { isAuthorized } = useAuthState()

  const Homepage = lazy(() => import('@/pages/Homepage'))
  const Markets = lazy(() => import('@/pages/Markets'))
  const Account = lazy(() => import('@/pages/Account'))
  const Market = lazy(() => import('@/pages/Market'))
  const ActivitiesTable = lazy(() => import('@/pages/Account/components/ActivitiesTable'))
  const PositionList = lazy(() => import('@/pages/Account/components/PositionList'))

  // eslint-disable-next-line
  const signInGuard = useCallback(
    ({ request }: LoaderFunctionArgs) => {
      const requestUrl = new URL(request.url)
      const from = requestUrl.searchParams.get('from')

      return isAuthorized
        ? redirect(from ? `${from}${requestUrl.search}` : RoutePaths.Markets)
        : null
    },
    [isAuthorized],
  )

  const authProtectedGuard = useCallback(
    // eslint-disable-next-line
    ({ request }: LoaderFunctionArgs) => {
      // If the user is not logged in and tries to access protected route, we redirect
      // them to sign in with a `from` parameter that allows login to redirect back
      // to this page upon successful authentication
      if (!isAuthorized) {
        // TODO: uncomment after auth implementation
        //   const requestUrl = new URL(request.url)
        //   requestUrl.searchParams.set('from', requestUrl.pathname)
        //
        //   return redirect(`${RoutePaths.Root}${requestUrl.search}`)
        return redirect(RoutePaths.Root)
      }

      return null
    },
    [isAuthorized],
  )

  // TODO: uncomment after auth implementation
  // const LayoutComponent = useMemo(() => {
  //   return MainLayout
  //   // return isAuthorized ? MainLayout : AuthLayout
  // }, [isAuthorized])

  const router = createBrowserRouter([
    {
      path: RoutePaths.Root,
      ErrorBoundary: () => (
        <FallbackComponent resetErrorBoundary={() => window.location.reload()} />
      ),
      element: (
        <Suspense fallback={<></>}>
          <Outlet />
        </Suspense>
      ),
      children: [
        {
          element: (
            <MainLayout>
              <Suspense fallback={<></>}>
                <Outlet />
              </Suspense>
            </MainLayout>
          ),
          children: [
            {
              index: true,
              element: <Navigate to={RoutePaths.Markets} replace={true} />,
            },
            {
              path: RoutePaths.Root,
              loader: authProtectedGuard,
              element: <Homepage />,
            },
            {
              path: RoutePaths.Markets,
              element: <Markets />,
            },
            {
              path: RoutePaths.Market,
              element: <Market />,
            },
            {
              path: RoutePaths.Account,
              loader: authProtectedGuard,
              element: <Account />,
              children: [
                {
                  index: true,
                  element: <Navigate to={RoutePaths.AccountPositions} replace />,
                },
                {
                  path: RoutePaths.AccountActivities,
                  element: <ActivitiesTable />,
                },
                {
                  path: RoutePaths.AccountPositions,
                  element: <PositionList />,
                },
              ],
            },
            {
              path: '*',
              element: <Navigate replace to={RoutePaths.Root} />,
            },
          ],
        },
      ],
    },
  ])

  return <RouterProvider router={router} />
}
